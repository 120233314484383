/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

import preloader from './sutosoft/preloader'
import globalConfig from './sutosoft/globalConfig'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    preloader,
    globalConfig
  }
})
