<template>
  <header class="header">
    <div class="header-wrapper" :class="{'shadow': isPositionBodyNotTop, 'menu-opened':isMenuVisible}">
      <div class="wrapper header-container">
        <div class="navigation-box">
          <div @click="scrollMeTo('main')" class="logo">
            <svg width="41" height="52" viewBox="0 0 41 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M21.0635 1.27746L20.5 0.997375L19.9365 1.27746L0.717704 10.8295L0.0150757 11.1787V11.9633V15.3596H2.5474V12.7479L20.5 3.82523L38.4526 12.7479V15.3596H40.9849V11.9633V11.1787L40.2823 10.8295L21.0635 1.27746ZM0.0150758 36.6405V40.0367V40.8214L0.717704 41.1706L19.9365 50.7226L20.5 51.0027L21.0635 50.7226L40.2823 41.1706L40.9849 40.8214V40.0367V36.6405H38.4526V39.2521L20.5 48.1748L2.5474 39.2521V36.6405H0.0150758ZM21.0885 12.1668L20.5804 11.9441L20.0722 12.1668L7.68682 17.5935L6.9288 17.9256V18.7532V21.8542V22.7565L7.78169 23.051L31.6996 31.31V32.681L20.5804 37.5529L9.46112 32.681V30.4076H6.9288V33.5086V34.3362L7.68682 34.6683L20.0722 40.095L20.5804 40.3177L21.0885 40.095L33.4739 34.6683L34.232 34.3362V33.5086V30.4076V29.5053L33.3791 29.2108L9.46112 20.9518V19.5808L20.5804 14.7089L31.6996 19.5808V21.8542H34.232V18.7532V17.9256L33.4739 17.5935L21.0885 12.1668Z"
                fill="white"/>
            </svg>
          </div>
          <MainMenu v-if="this.isDesktop"/>
        </div>
        <ContactUsBtn v-if="this.isDesktop"/>
        <div class="burger-mobile" v-if="!this.isDesktop" :class="{'active-mobile-btn':isMenuVisible}"
          @click="toggleVisibilityMenu">
          <div class="burger-mobile-line"></div>
        </div>
      </div>
    </div>
    <MainMenuModal
      v-if="!this.isDesktop"
      :class="{'opened':isMenuVisible}"/>
  </header>
</template>

<script>/* eslint-disable */
import MainMenuModal from '@/components/modals/MainMenuModal'
import MainMenu from '@/components/header-components/MainMenu'
import ContactUsBtn from '@/components/header-components/ContactUsBtn'
import ScrollHelper from '@/helpers/scroll.helper'

export default {
  name: 'Header',
  components: {MainMenuModal, ContactUsBtn, MainMenu},
  props: ['isPositionBodyNotTop'],
  data() {
    return {
      isDesktop: false,
      quantityClickable: 0
    }
  },
  computed: {
    isMenuVisible() {
      return this.$store.getters.IS_MENU_VISIBLE
    }
  },
  methods: {
    toggleVisibilityMenu() {
      if (this.isMenuVisible === true) {
        this.$store.dispatch('SET_IS_MENU_VISIBLE', false)
      } else {
        this.$store.dispatch('SET_IS_MENU_VISIBLE', true)
      }
    },

    scrollMeTo(id) {
      ScrollHelper.scrollMeTo(id)
      this.$store.dispatch('SET_IS_MENU_VISIBLE', false)
    },

    handleResize() {
      this.isDesktop = window.innerWidth >= 1200
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.handleResize()
  }
}
</script>

<style scoped lang="scss">
.header-wrapper {
  height: $header-height;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  transition: all, 0.25s;
  -webkit-tap-highlight-color: transparent;

  &.menu-opened, &.shadow {
    background-color: $primary-color;
  }

  @media only screen and (min-width: $body-width-md) {
    height: $header-height-md;
    background-color: $primary-color;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;

  @media only screen and (min-width: $body-width-sm) {
    min-width: auto;
  }
}

.logo {
  display: flex;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    background: $primary-color;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 90px;
    left: 50%;
    top: 50%;
  }

  svg {
    position: relative;
    z-index: 1;
  }

  @media only screen and (min-width: $body-width-md) {
    margin-right: 50px;
  }
}

.navigation-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

$height-burger-line: 4px;
$width-burger-line: 40px;
$height-burger-btn: 24px;
$indent-burger-line: -10px;
$radius-burger-line: 4px;

.burger-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $width-burger-line;
  height: $width-burger-line;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    background: $primary-color;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 90px;
    left: 50%;
    top: 50%;
  }

  .burger-mobile-box {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: $width-burger-line;
    height: $height-burger-btn;
  }

  .burger-mobile-line {
    position: absolute;
    z-index: 1;
    width: $width-burger-line;
    height: $height-burger-line;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: $radius-burger-line;
    background-color: $secondary-color;

    top: 50%;
    display: block;
    margin-top: -2px;

    &:before {
      display: block;
      content: '';
      top: $indent-burger-line;
      position: absolute;
      width: $width-burger-line;
      height: $height-burger-line;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      border-radius: $radius-burger-line;
      background-color: $secondary-color;
    }

    &:after {
      display: block;
      content: '';
      bottom: $indent-burger-line;
      position: absolute;
      width: $width-burger-line;
      height: $height-burger-line;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      border-radius: $radius-burger-line;
      background-color: $secondary-color;
    }
  }
}

.active-mobile-btn {
  .burger-mobile-line {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: rotate(45deg);

    &:after {
      bottom: 0;
      transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
      transform: rotate(-90deg);
    }

    &:before {
      top: 0;
      transition: top 75ms ease, opacity 75ms ease .12s;
      opacity: 0;
    }
  }
}

</style>
