<template>
  <section class="strategy section" id="strategy">
    <div class="wrapper">
      <div class="ttl-section">Strategy</div>
      <div class="ttl-navigation">
        <div v-for="(item, index) in strategies"
          :key="index" class="strategy__ttl-item"
          :class="{'active': indexCarousel >= index, 'mobile-active': indexCarousel === index}"
          @click="scrollMeTo(index)"
        >
          <span class="left-circle"></span>
          <div class="strategy_step">Step {{ index+1 }}:</div>
          {{ item.ttl }}
          <span class="right-circle"></span>
        </div>
      </div>
      <swiper :options="swiperOptionGallery" @transitionStart="changeIndexSlide" class="gallery-top"
        ref="swiperGalleryStrategy">
        <swiper-slide v-for="(item, index) in strategies" v-html="item.text"
          :key="index" class="strategy__gallery-item">
        </swiper-slide>
      </swiper>
      <div class="navigation-panel">
        <div class="btn-prev navigation-btn prev-strategy">
          <svg width="11" height="26" viewBox="0 0 11 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L2 13L10 25" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="pagination pagination-strategy"/>
        <div class="btn-next navigation-btn next-strategy">
          <svg width="11" height="26" viewBox="0 0 11 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 25L9 13L1 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>/* eslint-disable */
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

export default {
  name: 'Strategy',
  components: {Swiper, SwiperSlide},
  data() {
    return {
      indexCarousel: 0,
      strategies: [
        {
          ttl: 'Design',
          text: '<div class="simple-text">\n' +
            '            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, fames quam pretium, amet, viverra diam,\n' +
            '            iaculis. Euismod nullam pulvinar diam a. Tristique pharetra, amet, dictum maecenas.\n' +
            '          </div>\n' +
            '          <div class="simple-text">\n' +
            '            Mattis ultricies nascetur gravida vestibulum quis nulla adipiscing. Congue placerat id mattis magna enim.\n' +
            '            Pharetra eu egestas commodo ultrices et arcu, velit.\n' +
            '          </div>'
        },
        {
          ttl: 'Logic',
          text: '<div class="simple-text">\n' +
            '            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, fames quam pretium, amet, viverra diam,\n' +
            '            iaculis. Euismod nullam pulvinar diam a. Tristique pharetra, amet, dictum maecenas.\n' +
            '          </div>\n' +
            '          <div class="simple-text">\n' +
            '            Mattis ultricies nascetur gravida vestibulum quis nulla adipiscing. Congue placerat id mattis magna enim.\n' +
            '            Pharetra eu egestas commodo ultrices et arcu, velit.\n' +
            '          </div>'
        },
        {
          ttl: 'Development',
          text: '<div class="simple-text">\n' +
            '            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, fames quam pretium, amet, viverra diam,\n' +
            '            iaculis. Euismod nullam pulvinar diam a. Tristique pharetra, amet, dictum maecenas.\n' +
            '          </div>\n' +
            '          <div class="simple-text">\n' +
            '            Mattis ultricies nascetur gravida vestibulum quis nulla adipiscing. Congue placerat id mattis magna enim.\n' +
            '            Pharetra eu egestas commodo ultrices et arcu, velit.\n' +
            '          </div>'
        }
      ],
      swiperOptionGallery: {
        spaceBetween: 10,
        pagination: {
          el: '.pagination-strategy',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.next-strategy',
          prevEl: '.prev-strategy',
          clickable: true
        }
      }
    }
  },
  computed: {
    swiperGalleryStrategy() {
      return this.$refs.swiperGalleryStrategy.$swiper
    }
  },
  methods: {
    changeIndexSlide() {
      this.indexCarousel = this.$refs.swiperGalleryStrategy.$swiper.activeIndex
    },
    scrollMeTo(index) {
      this.swiperGalleryStrategy.slideTo(index)
    }
  },
  mounted() {

  }
}
</script>
<style lang="scss">

.gallery-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: $body-width-md) {
    justify-content: flex-start;
  }
}

.strategy {
  .simple-text {
    max-width: 690px;
    margin: 0 auto 15px;
  }
}

</style>
<style scoped lang="scss">

.strategy {
  @media only screen and (min-width: $body-width-md) {
    display: flex;
    align-items: flex-end;
  }
}

.ttl-navigation {
  @media only screen and (min-width: $body-width-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .left-circle, .right-circle {
    display: none;
    position: absolute;
    background-color: $primary-color;
    bottom: -38px;
    z-index: 1;
    width: 20px;
    height: 20px;
    border: 2px solid $secondary-color;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    transition: all, 0.25s;
    -webkit-tap-highlight-color: transparent;
  }

  @media only screen and (min-width: $body-width-md) {
    padding-bottom: 100px;

    .left-circle, .right-circle {
      display: flex;
    }

    .right-circle {
      right: 0;
    }
  }
}

.strategy__ttl-item {
  display: none;
  padding-bottom: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 2.5px;
  color: rgba(84, 133, 206, 0.5);
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: $secondary-color;
    width: 45px;
    transform: translateX(-50%);
    left: 50%;
  }

  &.mobile-active {
    display: block;
    color: $tertiary-colors;
  }

  @media only screen and (min-width: $body-width-md) {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;

    &:not(&:last-child) {
      margin-right: -20px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -30px;
      height: 4px;
      transform: translate(0, 0);
      background-color: #292C39;
    }

    &.active {
      color: $tertiary-colors;

      .left-circle, .right-circle {
        background: $secondary-color;
        color: $primary-color;
        z-index: 10;
      }

      &:after {
        background-color: $secondary-color;
      }
    }
  }
}

.strategy_step {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 2.5px;
  color: rgba(255, 255, 255, 0.6);
}

.strategy_ttl {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 2.5px;
  color: #5485CE;
}

</style>
