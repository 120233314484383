/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import {store} from './store'
import VueRouter from 'vue-router'
import router from './routes'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueMeta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
import VueNumber from 'vue-number-animation'
import VueAnalytics from 'vue-analytics';

Vue.use(VueMeta)
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(VueAwesomeSwiper)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: require(`@/assets/images/spinner.gif`),
  attempt: 1,
  observer: true,
  observerOptions: {
    rootMargin: '100px'
  }
})
Vue.use(VueNumber)
Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID,
  router
});


import '@/assets/scss/common.scss'


new Vue({
  store,
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
