<template>
  <div class="project-modal">
    <div class="project-modal__overview" @click="hideProjectModal"></div>
    <div class="project-modal__html">
      <div class="wrapper">
        <div class="project-modal__header" :class="{'desktop-frame': isDesktop && current_width_site === 2}">
          <div class="project-modal__ttl-wrapper">
            <div class="project-modal__ttl">
              {{ current_project.siteName }}
            </div>
            <div class="project-modal__short-description">
              {{ current_project.siteDescription }}
            </div>
          </div>
          <div class="project-modal__close-icon" @click="hideProjectModal">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="36.9162" height="2.0509"
                transform="matrix(0.707646 -0.706568 0.707646 0.706568 0.424805 26.0838)" fill="white"/>
              <rect width="36.9162" height="2.0509"
                transform="matrix(0.707646 0.706568 -0.707646 0.706568 1.45117 0.467087)" fill="white"/>
            </svg>
          </div>
        </div>
        <div class="project-modal__body">
          <div class="project-modal__setting-and-poster">
            <div class="project-modal__panel-setting">
              <div class="project-modal__setting-box" :class="{'active': current_width_site === index}"
                v-for="(item, index) in resolutions" @click="changeImageListResolution(index)" :key="item.width">
                <div class="project-modal__setting-item" v-if="current_project.images[index].length>0" :class="{'active': current_width_site === index}"
                  v-html="item.image"></div>
              </div>
            </div>
            <div class="project-modal__poster-frame-wrapper">
              <img
                class="project-modal__device-frame"
                :style="`width: ${resolutions[current_width_site].width_frame};
              height: ${resolutions[current_width_site].height_frame}`"
                :src="resolutions[current_width_site].frame"
                alt="">
              <div class="project-modal__poster-frame"
                :style="`margin: ${resolutions[current_width_site].margin};
              border-radius: ${resolutions[current_width_site].border_radius};`"
              >
                <vue-custom-scrollbar class="project-modal__image-poster-wrapper"
                  :style="`width: ${resolutions[current_width_site].width_content};
                height: ${resolutions[current_width_site].height_content}`"
                  :settings="settingsPoster">
                  <img class="project-modal__image-poster"
                    alt="" :src=" current_project.images[current_width_site][current_image_index]">
                </vue-custom-scrollbar>
              </div>
            </div>
          </div>
          <vue-custom-scrollbar class="project-modal__images" :settings="settings" v-if="current_project.images[current_width_site].length > 1">
            <div class="project-modal__image-item" :class="{'active': current_image_index === index}"
              @click="changeCurrentImage(index)" v-for="(image, index) in current_project.images[current_width_site]"
              :key="index">
              <img class="project-modal__image" :src="image" alt="">
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </div>
    <div class="loader-overview bg-loader" v-if="isLoader">
      <svg class="tea" width="70" height="70" viewBox="0 0 37 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.0819 17H3.02508C1.91076 17 1.01376 17.9059 1.0485 19.0197C1.15761 22.5177 1.49703 29.7374 2.5 34C4.07125 40.6778 7.18553 44.8868 8.44856 46.3845C8.79051 46.79 9.29799 47 9.82843 47H20.0218C20.639 47 21.2193 46.7159 21.5659 46.2052C22.6765 44.5687 25.2312 40.4282 27.5 34C28.9757 29.8188 29.084 22.4043 29.0441 18.9156C29.0319 17.8436 28.1539 17 27.0819 17Z" stroke="var(--secondary)" stroke-width="2"></path>
        <path d="M29 23.5C29 23.5 34.5 20.5 35.5 25.4999C36.0986 28.4926 34.2033 31.5383 32 32.8713C29.4555 34.4108 28 34 28 34" stroke="var(--secondary)" stroke-width="2"></path>
        <path id="teabag" fill="var(--secondary)" fill-rule="evenodd" clip-rule="evenodd" d="M16 25V17H14V25H12C10.3431 25 9 26.3431 9 28V34C9 35.6569 10.3431 37 12 37H18C19.6569 37 21 35.6569 21 34V28C21 26.3431 19.6569 25 18 25H16ZM11 28C11 27.4477 11.4477 27 12 27H18C18.5523 27 19 27.4477 19 28V34C19 34.5523 18.5523 35 18 35H12C11.4477 35 11 34.5523 11 34V28Z"></path>
        <path id="steamL" d="M17 1C17 1 17 4.5 14 6.5C11 8.5 11 12 11 12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="var(--secondary)"></path>
        <path id="steamR" d="M21 6C21 6 21 8.22727 19 9.5C17 10.7727 17 13 17 13" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
    </div>
  </div>
</template>

<script>/* eslint-disable */
import NumberHelper from '@/helpers/number.helper'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

export default {
  name: 'ProjectModal',
  props: ['current_project'],
  components: {
    vueCustomScrollbar
  },
  data() {
    return {
      settings: {
        wheelPropagation: false
      },
      settingsPoster: {
        wheelPropagation: false,
        scrollYMarginOffset: 550
      },
      current_width_site: NumberHelper.constructor.DEFAULT_INDEX_CURRENT_ITEM,
      isDesktop: false,
      isMobile: false,
      current_image_index: NumberHelper.constructor.DEFAULT_INDEX_CURRENT_ITEM,
      resolutions: [
        {
          width_content: '286px',
          height_content: '617px',
          width_frame: '340px',
          height_frame: '668px',
          margin: '23px 31px 23px 23px',
          border_radius: '37px',
          frame: require(`@/assets/images/mobile.png`),
          image: '<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M8.99995 12.6155H1.00003" stroke="white" stroke-width="0.615378" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '<path d="M4.99996 13.3848C4.90867 13.3848 4.81944 13.4118 4.74354 13.4625C4.66764 13.5133 4.60849 13.5853 4.57355 13.6697C4.53862 13.754 4.52948 13.8468 4.54729 13.9363C4.5651 14.0259 4.60906 14.1081 4.6736 14.1727C4.73815 14.2372 4.82039 14.2812 4.90992 14.299C4.99944 14.3168 5.09224 14.3076 5.17658 14.2727C5.26091 14.2378 5.33299 14.1786 5.38371 14.1027C5.43442 14.0268 5.46149 13.9376 5.46149 13.8463C5.46157 13.7857 5.44969 13.7256 5.42652 13.6696C5.40336 13.6136 5.36936 13.5626 5.32649 13.5198C5.28362 13.4769 5.23271 13.4429 5.17667 13.4197C5.12064 13.3966 5.06059 13.3847 4.99996 13.3848Z" fill="white"/>\n' +
            '<path d="M7.76919 0.923096H2.23079C1.55106 0.923096 1.00003 1.47412 1.00003 2.15385V13.846C1.00003 14.5258 1.55106 15.0768 2.23079 15.0768H7.76919C8.44892 15.0768 8.99995 14.5258 8.99995 13.846V2.15385C8.99995 1.47412 8.44892 0.923096 7.76919 0.923096Z" stroke="white" stroke-width="0.615378" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '</svg>\n'
        },
        {
          width_content: '505px',
          height_content: '570px',
          width_frame: '600px',
          height_frame: '707px',
          margin: '60px 38px 60px 22px',
          border_radius: '5px',
          frame: require(`@/assets/images/tablet.png`),
          image: '<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M12.3879 0.0256348H1.56219C0.999193 0.0256348 0.541138 0.48369 0.541138 1.04668V14.9038C0.541138 15.4666 0.999193 15.9247 1.56219 15.9247H12.3881C12.9511 15.9247 13.4092 15.4666 13.4092 14.9038V1.04668C13.409 0.48369 12.9509 0.0256348 12.3879 0.0256348ZM12.8105 14.9038C12.8105 15.1367 12.6209 15.3262 12.3879 15.3262H1.56219C1.32937 15.3262 1.13964 15.1367 1.13964 14.9038V1.04668C1.13964 0.813665 1.32917 0.624139 1.56219 0.624139H12.3881C12.6209 0.624139 12.8107 0.813665 12.8107 1.04668L12.8105 14.9038Z" fill="white"/>\n' +
            '<path d="M7.04727 13.3879C6.84797 13.3879 6.68637 13.5495 6.68637 13.7488C6.68637 13.9481 6.84797 14.1097 7.04727 14.1097C7.24657 14.1097 7.40817 13.9481 7.40817 13.7488C7.40817 13.5495 7.24657 13.3879 7.04727 13.3879Z" fill="white"/>\n' +
            '</svg>\n'
        },
        {
          width_content: '630px',
          height_content: '392px',
          width_frame: '900px',
          height_frame: '530px',
          margin: '40px 30px 97px 33px',
          border_radius: '4px',
          frame: require(`@/assets/images/desktop.png`),
          image: '<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M16.8554 0.984863H1.09477C0.929382 0.984863 0.795517 1.11893 0.795517 1.28412V11.0974C0.795517 11.2626 0.929382 11.3966 1.09477 11.3966H6.72729L6.44699 14.3399C6.44619 14.3489 6.44719 14.3576 6.44719 14.3666H4.9607C4.79532 14.3666 4.66145 14.5007 4.66145 14.6659C4.66145 14.831 4.79532 14.9651 4.9607 14.9651H13.2871C13.4525 14.9651 13.5863 14.831 13.5863 14.6659C13.5863 14.5007 13.4525 14.3666 13.2871 14.3666H11.6518C11.6518 14.3576 11.6528 14.3491 11.652 14.3399L11.3717 11.3966H16.8554C17.0208 11.3966 17.1546 11.2626 17.1546 11.0974V1.28412C17.1546 1.11893 17.0206 0.984863 16.8554 0.984863ZM11.0531 14.3666H7.04549L7.32838 11.3966H10.7704L11.0531 14.3666ZM16.5561 10.7983H1.39402V1.58357H16.5561V10.7983Z" fill="white"/>\n' +
            '</svg>\n'
        }
      ],
      isLoader: false
    }
  },
  methods: {
    changeImageListResolution(index) {
      this.current_width_site = index
      this.current_image_index = 0
      this.setLoader()
    },

    changeCurrentImage(index) {
      this.current_image_index = index
      this.setLoader()
    },

    setLoader() {
      this.isLoader = true
      setTimeout(()=> {
        this.isLoader = false
      }, 1000)
    },

    hideProjectModal() {
      this.$emit('toggleProjectModalVisibility')
    },

    handleResize() {
      this.isDesktop = window.innerWidth >= 1200
      this.isMobile = window.innerWidth < 760
      if (this.isDesktop && this.current_project.images[2].length>0) {
        this.current_width_site = 2
      } else if (!this.isDesktop && !this.isMobile && this.current_project.images[1].length>0) {
        this.current_width_site = 1
      } else {
        this.current_width_site = NumberHelper.constructor.DEFAULT_INDEX_CURRENT_ITEM
      }
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.handleResize()
    this.setLoader()

  }
}
</script>

<style scoped lang="scss">
.project-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-modal__overview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: rgba(0, 0, 0, .5);
}

.project-modal__html {
  padding: 20px;
  z-index: 1001;
  background: $primary-color;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .wrapper {
    padding: 0;
  }
}

.project-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid $primary-color;

  &.desktop-frame {
    margin-bottom: 50px;
  }
}


.project-modal__ttl {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
}

.project-modal__close-icon {
  cursor: pointer;
  margin-left: 20px;

  svg {
    rect {
      transition: all, 0.25s;
      -webkit-tap-highlight-color: transparent;
    }
  }

  &:hover {
    svg {
      rect {
        fill: red;
      }
    }
  }
}

.project-modal__short-description {
  font-style: italic;
  margin-bottom: 10px;

  strong {
    font-weight: 500;
  }
}

.project-modal__body {
  overflow-y: auto;

  @media only screen and (min-width: $body-width-md) {
    display: flex;
  }
}

.project-modal__setting-and-poster {
  @media only screen and (min-width: $body-width-md) {
    flex: 1;
  }
}

.project-modal__panel-setting {
  display: none;
  justify-content: flex-end;
  margin-bottom: 20px;

  @media only screen and (min-width: $body-width-sm) {
    display: flex;

    .project-modal__setting-box {
      &:last-child {
        display: none;
      }
    }
  }

  @media only screen and (min-width: $body-width-md) {

    .project-modal__setting-box {
      &:last-child {
        display: flex;
      }
    }
  }
}

.project-modal__setting-box {
  &.active {
    pointer-events: none;
  }
}

.project-modal__project-iframe {
  height: 250px;
  margin-bottom: 20px;

  @media only screen and (min-width: 550px) {
    height: 350px;
  }

  @media only screen and (min-width: $body-width-sm) {
    height: 450px;
  }

  @media only screen and (min-width: $body-width-md) {
    height: 500px;
  }
}

.project-modal__setting-item {
  height: 40px;
  padding: 0 15px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary-color;
  background-color: $primary-color;
  border: 1px solid $tertiary-colors;
  transition: all, 0.25s;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:hover {
    @media only screen and (min-width: $body-width-md) {
      opacity: .7;
    }
  }

  &.active {
    background: $tertiary-colors;
    box-shadow: 0 0 9px rgba(130, 176, 245, 0.6);
  }
}

.project-modal__images {
  display: flex;
  overflow-x: auto;

  @media only screen and (min-width: $body-width-md) {
    width: 220px;
    display: block;
    margin-left: 40px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 590px;
  }
}

.project-modal__image-item {
  margin-right: 10px;
  min-width: 210px;
  width: 210px;
  height: 130px;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px solid $secondary-color;
    opacity: 0;
    transition: all, 0.25s;
    -webkit-tap-highlight-color: transparent;
  }

  &.active {
    pointer-events: none;

    &:after {
      opacity: 1;
    }
  }

  @media only screen and (min-width: $body-width-md) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.project-modal__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 0;

  @media only screen and (min-width: $body-width-md) {
    max-height: 100%;
  }
}

.project-modal__poster-frame-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
}

.project-modal__device-frame {
  position: absolute;
  top: 0;
  left: 50%;
  height: 668px;
  transform: translateX(-50%);
}

.project-modal__poster-frame {
  overflow: hidden;
}

.project-modal__image-poster-wrapper {
  object-fit: contain;
  overflow-y: auto;
  position: relative;
}

.project-modal__image-poster-wrapper {
  max-height: 617px;
  background-color: #000;
  object-fit: contain;
  overflow-y: auto;
  position: relative;
}

.project-modal__image-poster {
  width: 100%;
}

.loader-overview {
  position: fixed;
  z-index: 1111;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-loader {
  position: fixed;
  z-index: 1111;
  background: rgba(0, 0, 0, .5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

svg.tea {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;

  path {
    stroke: #fff;
  }

  #teabag {
    transform-origin: top center;
    transform: rotate(3deg);
    animation: swing 2s infinite
  }

  #steamL {
    stroke-dasharray: 13;
    stroke-dashoffset: 13;
    animation: steamLarge 2s infinite
  }

  #steamR {
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: steamSmall 2s infinite
  }
}

@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: .6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: .6
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0
  }
}

</style>


<style lang="scss">
.project-modal__setting-item {
  &.active {
    pointer-events: none;
    svg {
      path {
        stroke: $primary-color;
      }
    }
  }
}
</style>
