<template>
  <div class="main-menu-modal">
    <MainMenu/>
    <ContactUsBtn/>
  </div>
</template>

<script>/* eslint-disable */

import MainMenu from '@/components/header-components/MainMenu'
import ContactUsBtn from '@/components/header-components/ContactUsBtn'
export default {
  name: 'MainMenuModal',
  components: {ContactUsBtn, MainMenu}
}
</script>

<style lang="scss">
.main-menu-modal {
  width: 100%;
  position: fixed;
  top: $header-height;
  bottom: 0;
  background: $primary-color;
  z-index: 11;
  border-top: 1px solid $secondary-color;
  padding: 50px 20px 20px;
  transform: translateX(100%);
  overflow-y: auto;
  transition: all, 0.25s;
  -webkit-tap-highlight-color: transparent;

  &.opened {
    transform: translateX(0);
  }
}

</style>
