/* eslint-disable */

class FormHelper {
  static get NAME() {
    return 'name'
  }

  static get EMAIL() {
    return 'email'
  }

  static get MESSAGE() {
    return 'message'
  }

  getContactFields() {
    return [
      this.constructor.NAME,
      this.constructor.EMAIL,
      this.constructor.MESSAGE
    ]
  }

  onChangeFormValidator(form_object_clone, form_object) {
    return JSON.stringify(form_object_clone) !== JSON.stringify(form_object)
  }
}

export default new FormHelper()
