/* eslint-disable */

class NumberHelper {

	static get DEFAULT_ID_CURRENT_ITEM() {
		return -1
	}

	static get DEFAULT_INDEX_CURRENT_ITEM() {
		return 0
	}
}

export default new NumberHelper
