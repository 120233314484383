<template>
  <section class="contacts section" id="contacts"  v-lazy-container="{ selector: 'img' }">
    <div class="wrapper">
      <div class="contacts__form-wrapper">
        <div class="ttl-section">Contact Us</div>
        <form :action='`https://formsubmit.co/${email_to}`' ref="form" method="POST">
          <input type="hidden" name="_captcha" value="false">
          <input type="hidden" name="_subject" value="SutoSoft">
          <input type="hidden" name="_next" :value="base_url+'?success=true'">
          <input type="text" name="_honey" style="display:none">
          <div class="form-fields-group">
            <div class="form-field-group">
              <input type="text" name="name" class="form-field-group__input" placeholder="Name"
                :class="{'error': $v.contact_form.name.$error}"
                v-model.trim="$v.contact_form.name.$model"
              />
              <div class="form-field-group__input-error" v-if="$v.contact_form.name.$error && !$v.contact_form.name.required">
                This field is required
              </div>
            </div>
            <div class="form-field-group">
              <input type="text" name="email" class="form-field-group__input" placeholder="Email"
                :class="{'error': $v.contact_form.email.$error}"
                v-model.trim="$v.contact_form.email.$model"
              />
              <div class="form-field-group__input-error"
                v-if="$v.contact_form.email.$error && !$v.contact_form.email.required">
                This field is required
              </div>
              <div class="form-field-group__input-error"
                v-if="$v.contact_form.email.$error && !$v.contact_form.email.email">
                Email address is incorrect.
              </div>
            </div>
          </div>
          <div class="form-field-group">
          <textarea class="form-field-group__input form-field-group__textarea" name="message"
            :class="{'error': $v.contact_form.message.$error}"
            v-model.trim="$v.contact_form.message.$model"
            placeholder="Message"></textarea>
            <div class="form-field-group__input-error" v-if="$v.contact_form.message.$error && !$v.contact_form.message.required">
              This field is required
            </div>
          </div>
          <div class="btn" @click="sendFormToEmail">Send</div>
        </form>
      </div>
    </div>
    <img class="contacts__image" alt="" :data-src="require('@/assets/images/tree-desktop.png')"/>
    <Footer/>
  </section>
</template>

<script>/* eslint-disable */
import { required, email } from 'vuelidate/lib/validators'
import FormHelper from '@/helpers/form.helper'
import Footer from '@/components/Footer'
import LocalStorageHelper from '@/helpers/localStorage.helper'

export default {
  name: 'ContactUs',
  components: {Footer},
  data() {
    return {
      email_to: process.env.VUE_APP_MAIL,
      base_url: process.env.VUE_APP_BASE_URL,
      contact_form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  validations: {
    contact_form: {
      name: { required },
      email: {email, required},
      message: {required},
    }
  },
  methods: {
    sendFormToEmail() {
      let errors = 0
      FormHelper.getContactFields().map((value) => {
        if (this.$v.contact_form[value] !== undefined) {
          this.$v.contact_form[value].$touch()
          if (this.$v.contact_form[value].$error) {
            errors += 1
          }
        }
      })
      if (!errors) {
        LocalStorageHelper.setLocalStorageItem('form-submitted', true)
        this.$refs.form.submit()
      }
    }
  }
}
</script>

<style scoped lang="scss">

.contacts {
  overflow: hidden;
  position: relative;

  @media only screen and (min-width: $body-width-md) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(18, 21, 35, 0) 0%, #121523 100%);
  }
}

.contacts__form-wrapper {
  margin-bottom: 40px;

  @media only screen and (min-width: $body-width-md) {
    width: 700px;
    margin-bottom: 100px;
  }
}


.contacts__image {
  display: none;
  width: 647px;
  height: 718px;
  object-fit: cover;
  position: absolute;
  bottom: -160px;
  opacity: .2;
  transform: translate(40%);
  right: calc((100vw - 1160px) / 2);

  @media only screen and (min-width: $body-width-md) {
    display: block;
  }
}
</style>
