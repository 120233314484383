<template>
  <section class="top-section" id="main"  v-lazy-container="{ selector: 'img' }">
    <div class="wrapper">
      <div class="content">
        <div class="ttl-section">SutoSoft <span>team</span></div>
        <div class="top-section__content">
          A team of professionals solving business problems with the help of innovative technological solutions.
        </div>
        <div class="buttons-wrapper">
          <div class="btn top-section-btn" @click="scrollMeTo('about')">
            About us
          </div>
          <div class="btn top-section-btn" @click="scrollMeTo('projects')">
            Projects
          </div>
        </div>
      </div>
      <img class="top-section-image top-section-image-mobile" alt="" :data-src="require('@/assets/images/tree-mobile.png')"/>
      <img class="top-section-image top-section-image-tablet" alt="" :data-src="require('@/assets/images/tree-tablet.png')"/>
      <img class="top-section-image top-section-image-desktop" alt="" :data-src="require('@/assets/images/tree-desktop.png')"/>

      <div class="btn-next-slide" @click="scrollMeTo('technology')">
        <svg width="24" height="44" viewBox="0 0 24 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="22" height="42" rx="11" stroke="#82B0F5" stroke-width="2"/>
          <rect x="11" y="8" width="2" height="28" rx="1" fill="#82B0F5"/>
        </svg>
      </div>
    </div>

  </section>
</template>

<script>/* eslint-disable */
import ScrollHelper from '@/helpers/scroll.helper'

export default {
  name: 'Main',
  data() {
    return {

    }
  },
  computed: {
    getCarousel() {
      return this.$store.getters.CAROUSEL
    }
  },
  methods: {
    scrollMeTo(id) {
      ScrollHelper.scrollMeTo(id)
    }
  }
}
</script>

<style scoped lang="scss">

.top-section {
  position: relative;
  display: flex;
  align-items: center;
  padding: 170px 0 150px;

  @media only screen and (max-height: 650px) {
    align-items: flex-start;
  }

  @media only screen and (min-width: $body-width-sm) {
  }

  @media only screen and (min-width: $body-width-md) {
    height: 100vh;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-section__content {
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  @media only screen and (min-width: $body-width-sm) {
    font-size: 18px;
    line-height: 32px;
  }
}

.buttons-wrapper {
  @media only screen and (min-width: $body-width-sm) {
    display: flex;
    justify-content: center;

    .top-section-btn {
      margin-right: 20px;
    }
  }

  @media only screen and (min-width: $body-width-md) {
    justify-content: flex-start;
  }
}

.btn {
  &:hover {
    color: $primary-color;
  }
}

.top-section-btn {
  background-color: transparent;
  border: 1px solid $secondary-color;
  color: $secondary-color;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.content {
  position: relative;
  z-index: 1;

  @media only screen and (min-width: $body-width-md) {
    width: 680px;
  }
}

.top-section-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.top-section-image-mobile {
  width: 483px;
  @media only screen and (min-width: $body-width-sm) {
    display: none;
  }
}

.top-section-image-tablet {
  display: none;
  width: 567px;

  @media only screen and (min-width: $body-width-sm) {
    display: block;
  }

  @media only screen and (min-width: $body-width-md) {
    display: none;
  }
}

.top-section-image-desktop {
  display: none;
  width: 354px;
  position: static;
  transform: translateX(0);

  @media only screen and (min-width: $body-width-md) {
    display: block;
  }
}

.btn-next-slide {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: none;

  @media only screen and (min-width: $body-width-md) {
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $tertiary-colors;
    animation: 1s linear 0s infinite alternate scroll;
  }
}

@keyframes scroll {
  /* 0 & 100% might not be needed */
  from {
    top: 8px;
  }
  to {
    top: 27px;
  }
}
</style>
