<template>
  <div id="page-404">
    <div class="page-404-content">
      <div class="page-404__title">404</div>
      <div class="page-404__sub-title">{{ $router.history.current.name }}</div>
      <router-link to="/" class="btn"
      >Back to Home</router-link>
    </div>
  </div>
</template>

<script>/* eslint-disable */

export default {
  name: 'Page404'
}

</script>

<style lang="scss">
#page-404 {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
	min-height: calc(100vh - 130px);
	text-align: center;

  .btn {
    margin: 0 auto;
  }
}

.page-404__title {
  font-family: 'SangBleu Sunrise', sans-serif;
  font-weight: 700;
  font-size: 54px;
  line-height: 56px;
  text-align: center;
  color: $secondary-color;
  margin: 0 auto 20px;

  @media only screen and (min-width: 760px) {
    font-size: 74px;
    line-height: 76px;
    margin: 0 auto 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 94px;
    line-height: 96px;
    margin: 0 auto 50px;
  }
}

.page-404__sub-title {
  font-family: 'SangBleu Sunrise', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: $secondary-color;
  margin: 0 auto 20px;

  @media only screen and (min-width: 760px) {
    font-size: 34px;
    line-height: 36px;
    margin: 0 auto 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 44px;
    line-height: 46px;
    margin: 0 auto 40px;
  }
}

.page-404__description {
  font-family: 'TT Commons', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #243772;
  margin: 0 auto 3px;

  @media only screen and (min-width: 760px) {
    font-size: 18px;
    line-height: 20px;
    margin: 0 auto 5px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
    line-height: 22px;
  }
}

</style>
