<template>
  <section class="projects section" id="projects" v-lazy-container="{ selector: 'img' }">
    <div class="wrapper">
      <div class="ttl-section">Projects</div>
    </div>
    <swiper ref="mySwiper" class="projects-list" :options="swiperOptionsProjects">
      <swiper-slide class="project-item" v-for="(item, index) in websites"
        :key="index"
      >
        <div class="project-item__site-poster">
          <span></span>
          <img :data-src="item.poster" alt="poster"/>
          <div class="project-item__site-buttons">
            <div class="btn btn-small" @click="showProjectModal(item)">Show more</div>
            <a :href="item.link" target="_blank" class="btn btn-small">Go to Website</a>
          </div>
        </div>
        <div class="project-item__website-info">
          <div class="project-item__website-name">{{ item.siteName }}</div>
          <div class="project-item__website-description">{{ item.siteDescription }}</div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="navigation-panel">
      <div class="btn-prev navigation-btn prev-project">
        <svg width="11" height="26" viewBox="0 0 11 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1L2 13L10 25" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="pagination pagination-projects-new"/>
      <div class="btn-next navigation-btn next-project">
        <svg width="11" height="26" viewBox="0 0 11 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 25L9 13L1 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
  </section>
</template>

<script>/* eslint-disable */
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

export default {
  name: 'Projects',
  components: { Swiper, SwiperSlide},
  data() {
    return {
      swiperOptionsProjects: {
        spaceBetween: 30,
        effect: 'coverflow',
        slidesPerView: 1,
        lazy: {
          loadPrevNext: true
        },
        coverflowEffect: {
          rotate: 30,
          slideShadows: false,
        },
        navigation: {
          nextEl: '.next-project',
          prevEl: '.prev-project',
          clickable: true
        },
        pagination: {
          el: '.pagination-projects-new',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          600: {
            slidesPerView: 2
          },
          1200: {
            slidesPerView: 3
          }
        }
      },
      websites: [
        {
          siteName: 'GlobeIn',
          siteDescription: 'Premium full-size handmade products.',
          link: 'https://globein.com/membership',
          poster: require('@/assets/images/projects/globein.jpg'),
          images:  [
            [
              require('@/assets/images/projects/mobile-globein.jpg')
            ],
            [
              require('@/assets/images/projects/tablet-globein.jpg')
            ],
            [
              require('@/assets/images/projects/desktop-globein.jpg')
            ]
          ]
        },
        {
          siteName: 'Grapeline',
          siteDescription: 'Premier wine tour company',
          link: 'https://gogrape.com/',
          poster: require('@/assets/images/projects/gogrape.jpg'),
          images:  [
            [
              require('@/assets/images/projects/Gmob.jpg')
            ],
            [
              require('@/assets/images/projects/Gtab.jpg')
            ],
            [
              require('@/assets/images/projects/Gdesk.jpg')
            ]
          ],
        },
        {
          siteName: 'Abodoo',
          siteDescription: 'Skills data mapping and matching for the new world of work',
          link: 'https://www.abodoo.com/',
          poster: require('@/assets/images/projects/abodooPrev.jpg'),
          images:  [
            [
              require('@/assets/images/projects/abodooMob.jpg')
            ],
            [
              require('@/assets/images/projects/abodooTab.jpg')
            ],
            [
              require('@/assets/images/projects/abodooDesk.jpg')
            ]
          ],
        },
        {
          siteName: 'CryptoPack',
          siteDescription: 'NTF image generator',
          link: 'https://cryptopack.site.sutosoft.co/',
          poster: require('@/assets/images/projects/CryptoPack_(D)-min.png'),
          images:  [
            [
              require('@/assets/images/projects/CryptoPack_(M)-min.png')
            ],
            [
              require('@/assets/images/projects/CryptoPack_(T)-min.png')
            ],
            [
              require('@/assets/images/projects/CryptoPack_(D)-min.png')
            ]
          ]
        },
        {
          siteName: '24Slides Templates',
          siteDescription: 'Free Powerpoint Templates',
          link: 'https://24slides.com/templates/featured',
          poster: require('@/assets/images/projects/24prev.jpg'),
          images:  [
            [
              require('@/assets/images/projects/24mob.jpg')
            ],
            [
              require('@/assets/images/projects/24tabs.jpg')
            ],
            [
              require('@/assets/images/projects/24desk.jpg')
            ]
          ],
        },
        {
          siteName: 'Exempt EDGE',
          siteDescription: 'Software solutions for all participants of the Canadian Private Capital Markets',
          link: 'https://exemptedge.com/',
          poster: require('@/assets/images/projects/edgeprev.jpg'),
          images:  [
            [
              require('@/assets/images/projects/edgemob.jpg')
            ],
            [
              require('@/assets/images/projects/edgetabs.jpg')
            ],
            [
              require('@/assets/images/projects/edgeDesk.jpg')
            ]
          ],
        },
        {
          siteName: 'Folkrace',
          siteDescription: 'Application for auto race process managing',
          link: 'https://folkrace.online/',
          poster: require('@/assets/images/projects/Idag.jpg'),
          images:  [
            [
              require('@/assets/images/projects/Idag.jpg'),
              require('@/assets/images/projects/folk2.jpg'),
              require('@/assets/images/projects/folkrace3.jpg'),
              require('@/assets/images/projects/folk1.jpg'),
              require('@/assets/images/projects/ClashoftheTitans_12.jpg')
            ],
            [

            ],
            [

            ]
          ],
        },
        {
          siteName: 'GlobeIn Shop',
          siteDescription: 'Shopify store for hand crafted Artisan Goods',
          link: 'https://shop.globein.com/',
          poster: require('@/assets/images/projects/globein.com_membership.jpg'),
          images:  [
            [
              require('@/assets/images/projects/Group10226.jpg')
            ],
            [
              require('@/assets/images/projects/Group10227.jpg')
            ],
            [
              require('@/assets/images/projects/Group10225.jpg')
            ]
          ]
        }
      ]
    }
  },
  methods: {
    showProjectModal(project) {
      this.$emit('toggleProjectModalVisibility', project)
    }
  }
}
</script>

<style lang="scss">

.projects {

  .navigation-panel {
    margin-top: 0;
  }

  @media only screen and (min-width: $body-width-md) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .navigation-panel {
      bottom: 30px;
    }
  }
}

.project-item__site-poster {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;

  &:before, &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    transition: all, 0.6s;
    -webkit-tap-highlight-color: transparent;
    opacity: 0;
    z-index: 2;
  }

  &:before {
    top: calc(5% - .5px);
    left: calc(5% - .5px);
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;

  }

  &:after {
    right: calc(5% - .5px);
    bottom: calc(5% - .5px);
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  span {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    transition: all, 0.25s;
    -webkit-tap-highlight-color: transparent;
    background-color: rgba(0, 0, 0, .5);
  }

  @media only screen and (max-width: 1199px) {
    span {
      opacity: 1;
    }

    &:after, &:before {
      content: '';
      width: 90%;
      height: 90%;
      opacity: 1;
    }

    .project-item__website-info {
      bottom: 0;
      opacity: 1;
    }

    .project-item__site-buttons {
      opacity: 1;
      top: 50%;
    }
  }

  &:hover {
    span {
      opacity: 1;
    }

    &:after, &:before {
      content: '';
      width: 90%;
      height: 90%;
      opacity: 1;
    }

    .project-item__website-info {
      bottom: 0;
      opacity: 1;
    }

    .project-item__site-buttons {
      opacity: 1;
      top: 50%;
    }
  }

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top;
  }
}

.project-item__site-buttons {
  position: absolute;
  text-align: center;
  z-index: 3;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all, 0.25s;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (min-width: $body-width-sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn {
    -webkit-tap-highlight-color: transparent;
    position: relative;

    &:hover {
      opacity: .7;
    }
  }

  * {
    margin: 15px auto;

    @media only screen and (min-width: $body-width-sm) {
      margin: 5px;
    }
  }
}

.project-item {
  width: 100%;

  @media only screen and (min-width: 650px) {
    width: 49%;
  }

  @media only screen and (min-width: $body-width-md) {
    width: 32%;
  }
}

.project-item__website-info {

}

.project-item__website-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.project-item__website-description {
  font-size: 13px;
  font-style: italic;
}

.projects-list {
  margin-bottom: 60px;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: $body-width-sm) {
    padding: 0;
    width: 700px;
    max-width: 100%;
    margin: 0 auto 50px;
  }

  @media only screen and (min-width: $body-width-md) {
    padding-left: calc((100vw - 1160px) / 2);
    padding-right: calc((100vw - 1160px) / 2);
    width: 100%;
  }
}

.swiper-slide {
  @media only screen and (min-width: $body-width-md) {
    margin-right: 30px;
  }
}

</style>
