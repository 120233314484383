/* eslint-disable */

class LocalStorageHelper {
	setLocalStorageItem(key, value) {
		localStorage.setItem(key, value)
	}

	getLocalStorageItem(key) {
		let value = localStorage.getItem(key)
		if (value !== null) {
			return value
		} else {
			return undefined
		}
	}

	removeLocalStorageItem(key) {
		localStorage.removeItem(key)
	}

	clearLocalStorage() {
		localStorage.clear()
	}
}

export default new LocalStorageHelper