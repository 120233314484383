/* eslint-disable */
export default {
  state: {
    carousel_arr: [],
    is_menu_visible: false
  },
  getters: {
    CAROUSEL: state => {
      return state.carousel_arr
    },
    IS_MENU_VISIBLE: state => {
      return state.is_menu_visible
    }
  },
  mutations: {
    SET_CAROUSEL: (state, payload) => {
      state.carousel_arr = payload
    },
    SET_IS_MENU_VISIBLE: (state, payload) => {
      state.is_menu_visible = payload
    }
  },
  actions: {
    SET_CAROUSEL: async (context, payload) => {
      context.commit('SET_CAROUSEL', payload)
    },
    SET_IS_MENU_VISIBLE: async (context, payload) => {
      context.commit('SET_IS_MENU_VISIBLE', payload)
    }
  }
}
