<template>
  <nav class="main-menu">
    <div @click="scrollMeTo(item.id)" class="main-menu__item" v-for="(item, index) in mainMenu" :key="index">
      {{ item.title }}
    </div>
  </nav>
</template>

<script>
import ScrollHelper from '@/helpers/scroll.helper'
export default {
  name: 'MainMenu',
  data() {
    return {

    }
  },
  computed: {
    mainMenu() {
      return [
        {
        title: 'Main',
          id: 'main'
      },
        {
          title: 'Technology',
          id: 'technology'
        },
        {
          title: 'Projects',
          id: 'projects'
        },
        {
          title: 'About us',
          id: 'about'
        }
      ]
    },

    getCarousel() {
      return this.$store.getters.CAROUSEL
    }
  },
  methods: {
    scrollMeTo(id) {
      ScrollHelper.scrollMeTo(id)
      this.$store.dispatch('SET_IS_MENU_VISIBLE', false)
    }
  }
}
</script>

<style lang="scss">
.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  @media only screen and (min-width: $body-width-md) {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.main-menu__item {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: $secondary-color;
  text-decoration: none;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;

  @media only screen and (min-width: $body-width-md) {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
    margin-right: 40px;
  }

  &:after {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    background: $secondary-color;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    transition: all, 0.25s;
    -webkit-tap-highlight-color: transparent;

    @media only screen and (min-width: $body-width-md) {
      height: 1px;
    }
  }

  &:hover, &.router-link-exact-active {
    &:after {
      width: 100%;
    }
  }
}

</style>
