import VueRouter from 'vue-router'
/* eslint-disable */

import Page404 from '@/components/Page404.vue'
import Page403 from '@/components/Page403.vue'
import MainPage from '@/components/MainPage'

export default new VueRouter({
	mode: 'history',
	hash: false,
	routes: [
		{
			name: 'Home',
			path: '/',
			component: MainPage,
		},
		{
			name: 'Not Founds',
			path: '/not-founds',
			component: Page404,
		},
		{
			name: 'Not Founds',
			path: '*',
			component: Page404
		},
		{
			name: 'Access denied',
			path: '/forbidden',
			component: Page403
		}
	],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

