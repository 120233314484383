<template>
  <div @click="scrollMeTo('contacts')" class="btn btn-header">
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.1111 0.4375H0.888902C0.643443 0.4375 0.444458 0.633375 0.444458 0.875V13.125C0.444458 13.3666 0.643443 13.5625 0.888902 13.5625H20.1111C20.3566 13.5625 20.5556 13.3666 20.5556 13.125V0.875C20.5556 0.633375 20.3566 0.4375 20.1111 0.4375Z" stroke="#0A0B11" stroke-width="1.18519" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.5556 0.875L10.7778 8.65375C10.6976 8.72037 10.5961 8.75692 10.4911 8.75692C10.3862 8.75692 10.2846 8.72037 10.2045 8.65375L0.444458 0.875" stroke="#0A0B11" stroke-width="1.18519" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.2223 13.5625L12.8667 7" stroke="#0A0B11" stroke-width="1.18519" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M0.888916 13.5625L8.00003 7.21875" stroke="#0A0B11" stroke-width="1.18519" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    Contact Us
  </div>
</template>

<script>
import ScrollHelper from '@/helpers/scroll.helper'
export default {
  name: 'ContactUsBtn',
  data() {
    return {
      index_contact_section: 5
    }
  },
  methods: {
    scrollMeTo(id) {
      ScrollHelper.scrollMeTo(id)
      this.$store.dispatch('SET_IS_MENU_VISIBLE', false)
    },
  }
}
</script>

<style scoped lang="scss">
.btn-header {
  background-color: $tertiary-colors;
  font-weight: 400;

  @media only screen and (min-width: $body-width-sm) {
    margin: 0 auto;
  }

  @media only screen and (min-width: $body-width-md) {
    margin: 0;
  }

  &:hover {
    background-color: darken($tertiary-colors, 7%);;
  }
}
</style>
