class ScrollHelper {

  scrollMeTo(id) {
    let element = document.getElementById(id)
    let top = element.offsetTop;
    window.scrollTo({
      left: 0,
      top: top - 100,
      behavior: 'smooth'
    });
  }
}

export default new ScrollHelper
