<template>
  <section class="about-us section" id="about"  v-lazy-container="{ selector: 'img' }">
    <div class="wrapper">
      <div class="about-us__content-thumbs">
        <div class="ttl-section">About us</div>
        <div class="about-us__content simple-text">
          We are from Ukraine! Our team was formed in 2016.
          The goal was and remains to create high-quality web applications that help businesses grow and develop.
          Our specialists are professionals in their fields and are ready to help you solve any problems.
          We will be very glad to cooperate.
        </div>
        <vue-custom-scrollbar class="about-us__image-thumbs-list" :settings="settings" v-if="images_list.length > 1">
          <div class="about-us__scroll-x">
            <div class="about-us__image-thumb"
              :class="{'active': current_image_index === index}"
              @click="changeCurrentImage(index)"
              v-for="(image, index) in images_list"
              :key="image.src"
            >
              <img :data-src="image.src" alt="">
            </div>
          </div>
        </vue-custom-scrollbar>
        <div class="about-us__our-info" v-if="isDesktop">
          <div class="about-us__item"
            v-for="(item,index) in our_info_arr"
            :key="item.value"
          >
            <div class="about-us__item-value">
              <number
                tag="div"
                animationPaused
                ref="number2"
                :to="item.value"
                :duration="2"
                easing="Power1.easeOut"
              />{{index === 0? '+':''}}
            </div>
            <div class="about-us__item-label">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="about-us__current-image-wrap">
        <div class="about-us__image-item" :class="{'active': current_image_index === index}"
          v-for="(image, index) in images_list"
          :key="image.src"
        >
          <img :data-src="image.src" alt="about-us">
        </div>
      </div>
      <div class="about-us__our-info" v-if="!isDesktop">
        <div class="about-us__item"
          v-for="(item, index) in our_info_arr"
          :key="item.value"
        >
          <div class="about-us__item-value">
            <number
              tag="div"
              animationPaused
              ref="number2"
              :to="item.value"
              :duration="2"
              easing="Power1.easeOut"
            />{{index === 0? '+':''}}
           </div>
          <div class="about-us__item-label">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>/* eslint-disable */

import NumberHelper from '@/helpers/number.helper'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: 'AboutUs',
  props: ['scrollOnAboutSection'],
  components: {
    vueCustomScrollbar
  },
  data() {
    return {
      isDesktop: false,
      settings: {
        wheelPropagation: false,
        suppressScrollY: true
      },
      current_image_index: NumberHelper.constructor.DEFAULT_INDEX_CURRENT_ITEM,
      images_list: [
        {
          src: require('@/assets/images/about-us/team-image.jpg')
        }
      ],
      our_info_arr: [
        {
          label: 'Happy clients',
          value: '35'
        },
        {
          label: 'Team members',
          value: '17'
        }
      ]
    }
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth >= 1200
    },
    changeCurrentImage(index) {
      this.current_image_index = index
    },

    playAnimation() {
      Object.values(this.$refs.number2).forEach((item, index) => {
        item.restart()
      })
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  watch: {
    scrollOnAboutSection() {
      if (this.scrollOnAboutSection) {
        this.playAnimation()
      }
    }
  }
}
</script>

<style scoped lang="scss">

.about-us {

  @media only screen and (min-width: $body-width-md) {
    display: flex;
    align-items: center;
  }
}

.about-us__content {
  margin-bottom: 30px;
  max-width: 580px;
}

.wrapper {
  @media only screen and (min-width: $body-width-md) {
    display: flex;
    align-items: center;
  }
}

.about-us__image-thumbs-list {
  margin-bottom: 30px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden !important;

  @media only screen and (min-width: $body-width-md) {
    margin-bottom: 175px;
  }
}

.about-us__scroll-x {
  display: flex;
  justify-content: flex-start;
  min-width: max-content;
}

.about-us__image-thumb {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px solid $secondary-color;
    opacity: 0;
    transition: all, 0.25s;
    -webkit-tap-highlight-color: transparent;
  }

  &.active {
   &:after {
     opacity: 1;
   }
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.about-us__current-image-wrap {
  margin-bottom: 40px;

  @media only screen and (min-width: $body-width-sm) {
    margin-bottom: 50px;
  }

  @media only screen and (min-width: $body-width-md) {
    margin-left: 50px;
    margin-bottom: 0;
  }
}

.about-us__image-item {
  visibility: hidden;
  position: absolute;
  -webkit-tap-highlight-color: transparent;

  &.active {
    animation: scroll .25s linear;
    visibility: visible;
    position: relative;
  }

  @media only screen and (min-width: $body-width-md) {
    @keyframes scroll {
      0% {
        right: -100%;
        opacity: 0;
        transform: scale(0.5);
      }
      50% {
        right: 0;
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }


  @media only screen and (min-width: $body-width-md) {
    width: 530px;
  }

  img {
    width: 100%;
    display: block;
    height: 180px;
    object-fit: contain;

    @media only screen and (min-width: $body-width-sm) {
      height: 300px;
    }

    @media only screen and (min-width: $body-width-md) {
      height: 530px;
    }
  }
}

.about-us__our-info {
  display: flex;
}

.about-us__item {
  padding-left: 20px;
  border-left: 1px solid $secondary-color;

  &:first-child {
    margin-right: 100px;
  }
}

.about-us__item-value {
  font-size: 26px;
  line-height: 32px;
  color: $tertiary-colors;
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  * {
    color: $tertiary-colors;
  }
}

.about-us__item-label {
  font-size: 14px;
  line-height: 24px;
  color: $secondary-color;
}
</style>
