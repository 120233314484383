/* eslint-disable */
export default {
  state: {
    globalPreloaderCounter: 0,
    globalPreloaderVisibility: true,
    isResponse: false
  },
  getters: {
    GLOBAL_PRELOADER_COUNTER: state => {
      return state.globalPreloaderCounter
    },

    GLOBAL_PRELOADER_VISIBILITY: state => {
      return state.globalPreloaderVisibility
    },

    STATUS_REQUEST: state => {
      return state.isResponse
    }
  },
  mutations: {
    GLOBAL_PRELOADER_COUNTER_INCREMENT: (state) => {
      state.globalPreloaderCounter++
    },

    GLOBAL_PRELOADER_COUNTER_DECREMENT: (state) => {
      state.globalPreloaderCounter--
    },

    SET_GLOBAL_PRELOADER_VISIBILITY: (state, payload) => {
      state.globalPreloaderVisibility = payload
    },

    SET_STATUS_REQUEST: (state, payload) => {
      state.isResponse = payload
    }
  },
  actions: {
    GLOBAL_PRELOADER_COUNTER_INCREMENT: async (context) => {
      context.commit('GLOBAL_PRELOADER_COUNTER_INCREMENT')
    },

    GLOBAL_PRELOADER_COUNTER_DECREMENT: async (context) => {
      context.commit('GLOBAL_PRELOADER_COUNTER_DECREMENT')
    },

    SET_GLOBAL_PRELOADER_VISIBILITY: async (context, payload) => {
      context.commit('SET_GLOBAL_PRELOADER_VISIBILITY', payload)
    },

    SET_STATUS_REQUEST: async (context, payload) => {
      context.commit('SET_STATUS_REQUEST', payload)
    }
  }
}
