<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>/* eslint-disable */

export default {
  name: 'App',
  metaInfo: {
    title: 'SutoSoft - software development team',
    titleTemplate: null,
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      {charset: 'utf-8'},
      {
        name: 'description',
        content: 'A team of professionals solving business problems with the help of innovative technological solutions.'
      },
      {name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'}
    ]
  }
}
</script>

<style lang="scss" scoped>

</style>
