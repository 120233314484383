<template>
  <div class="suto-soft" id="suto-soft">
    <Header :isPositionBodyNotTop="isPositionBodyNotTop"/>
    <Main/>
    <Technology/>
    <!--    <Strategy/>-->
    <Projects
      @toggleProjectModalVisibility="toggleProjectModalVisibility"
    />
    <AboutUs :scrollOnAboutSection="scrollOnAboutSection"/>
    <ContactUs/>
    <div class="back-to-top" :class="{'active': isPositionBodyNotTop}" @click="scrollMeTo('main')">
      <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" xml:space="preserve">
        <path
          d="M16.714,11.297c-0.389-0.389-1.039-0.389-1.429,0l-8.999,8.976  c-0.394,0.394-0.394,1.033,0,1.426c0.394,0.394,1.034,0.394,1.428,0L16,13.436l8.285,8.264c0.395,0.394,1.034,0.394,1.429,0  c0.394-0.394,0.394-1.033,0-1.426L16.714,11.297z"
          fill="#121313" id="Expand_Less"/>
       </svg>
    </div>
    <ProjectModal v-if="is_project_modal_visible"
      @toggleProjectModalVisibility="toggleProjectModalVisibility"
      :current_project="current_project"
    />
    <div class="success__overlay" v-if="isFormSend && isFormSubmitted" @click="hideNotification"></div>
    <div class="success-notification" :class="{'success': isFormSend && isFormSubmitted }">
      <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M38 0.734375C28.12 0.745541 18.6478 4.67532 11.6615 11.6615C4.67532 18.6478 0.745541 28.12 0.734375 38C2.84375 87.4438 73.2266 87.4297 75.2656 38C75.2545 28.12 71.3247 18.6478 64.3385 11.6615C57.3522 4.67532 47.88 0.745541 38 0.734375ZM57.8703 24.8656L35.2719 53.7359C35.0761 53.9898 34.8247 54.1954 34.5371 54.3368C34.2494 54.4783 33.9331 54.5518 33.6125 54.5518C33.2919 54.5518 32.9756 54.4783 32.6879 54.3368C32.4003 54.1954 32.1489 53.9898 31.9531 53.7359L18.1297 36.1578C17.8041 35.7163 17.6635 35.1651 17.7375 34.6215C17.8116 34.078 18.0945 33.5845 18.5263 33.2462C18.958 32.9077 19.5047 32.7508 20.0502 32.8087C20.5958 32.8665 21.0973 33.1347 21.4484 33.5563L33.5984 49.025L54.5375 22.3063C54.8943 21.9183 55.3835 21.6781 55.9086 21.6327C56.4337 21.5874 56.9568 21.7402 57.3747 22.0613C57.7928 22.3822 58.0755 22.8481 58.1672 23.3671C58.259 23.8862 58.153 24.4208 57.8703 24.8656Z"
          fill="#82B0F5"/>
      </svg>
      <div class="notification__ttl">Thank you for your message</div>
      <div class="notification__short-description">We will contact you soon.</div>
      <div class="btn notification__btn" @click="hideNotification">Done</div>
    </div>
  </div>
</template>

<script>/* eslint-disable */

import Header from '@/components/header-components/Header'
import Main from '@/components/sections/Main'
import AboutUs from '@/components/sections/AboutUs'
import Strategy from '@/components/sections/Strategy'
import Projects from '@/components/sections/Projects'
import Technology from '@/components/sections/Technology'
import ContactUs from '@/components/sections/ContactUs'
import ProjectModal from '@/components/modals/ProjectModal'
import ScrollHelper from '@/helpers/scroll.helper'
import LocalStorageHelper from '@/helpers/localStorage.helper'

export default {
  components: {ProjectModal, ContactUs, Technology, Projects, Strategy, AboutUs, Header, Main},
  data() {
    return {
      current_project: {},
      isPositionBodyNotTop: false,
      is_project_modal_visible: false,
      scrollOnAboutSection: false,
      isFormSend: false,
      isFormSubmitted: false
    }
  },
  computed: {
    getCarousel() {
      return this.$store.getters.CAROUSEL
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.isFormSend = this.$route.query.success
    this.checkIsFormSubmitted()
    this.delayNotification()
  },
  methods: {
    checkIsFormSubmitted() {
      this.isFormSubmitted = LocalStorageHelper.getLocalStorageItem(`form-submitted`) !== undefined
    },
    delayNotification() {
      if (this.isFormSend) {
        setTimeout(() => {
          this.hideNotification()
        }, 10000)
      }
    },

    hideNotification() {
      this.isFormSend = false
      LocalStorageHelper.removeLocalStorageItem('form-submitted')
    },

    scrollMeTo(id) {
      ScrollHelper.scrollMeTo(id)
      this.$store.dispatch('SET_IS_MENU_VISIBLE', false)
    },

    checkPositionScroll() {
      let element = document.getElementById('about')
      let top = element.offsetTop;
      this.scrollOnAboutSection = window.scrollY > top - 200;
    },

    handleScroll() {
      this.isPositionBodyNotTop = window.scrollY > 90
      this.checkPositionScroll()
    },

    toggleProjectModalVisibility(val) {
      this.current_project = val
      this.is_project_modal_visible = !this.is_project_modal_visible
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">

footer {
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgb(0, 0, 0);
  font-size: 12px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: -50px;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
  transition: all, 0.25s;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  animation-duration: 0.25s;

  &.active {
    right: 20px;
  }

  &:hover {
    background-color: darken($secondary-color, 30%);
  }
}

.success__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(18, 21, 35, 0.4);
  z-index: 1002;
}

.success-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: -1000px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  max-width: calc(100% - 40px);
  height: 304px;
  z-index: 1003;
  background: #F1F1F1;
  transition: all, 0.25s;
  -webkit-tap-highlight-color: transparent;
  padding: 10px 20px 10px 15px;

  svg {
    margin-bottom: 17px;
  }

  &.success {
    top: 50%;
  }
}

.notification__ttl {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
  text-align: center;
  color: #121523;
}

.notification__short-description {
  line-height: 20px;
  color: rgba(18, 21, 35, 0.5);
  margin-bottom: 40px;
}

.notification__btn {
  color: #0A0B11;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  width: 100% !important;
  background: #82B0F5 !important;

  &:hover {
    opacity: .8;
  }
}

</style>
